<script setup lang="ts">
import type { HouseDestination, HouseDetails } from '~/types/house/types'

const props = withDefaults(
  defineProps<{
    house: HouseDetails
    houseDestinations?: HouseDestination
    getGps: { latitude: number; longitude: number }
  }>(),
  {
    houseDestinations: () => ({}) as HouseDestination,
  },
)

const { t } = useI18n()
const { isDesktopOrTablet } = useBreakpoint()

const environments = computed(() => {
  const setting = props.house?.setting || {}
  const entries = Object.entries(setting)

  return entries?.reduce<{ trad: string }[]>((acc, [type, value]) => {
    if (type === 'locatedInCity') {
      const trad = value
        ? 'house.environments.inTown'
        : 'house.environments.outOfTown'
      acc.push({ trad })
    } else if (type === 'neighborhood') {
      if (!value) acc.push({ trad: 'house.environments.noNeighbors' })
    } else if (type && value) {
      acc.push({ trad: `house.environments.${type}` })
    }

    return acc
  }, [])
})

const nearBy = computed(() => {
  const nearby = props.house?.nearby || {}
  const entries = Object.entries(nearby)

  const filteredAccess = ['ski_in', 'ski_out', 'ski_in_ski_out']

  return entries?.reduce<{ trad: string; time: string; access?: string }[]>(
    (acc, [key, values]) => {
      const [access, time] = values
      const formattedTime = `${time} minutes`

      if (filteredAccess.includes(String(access))) {
        acc.push({
          trad: `house.nears.${access}`,
          time: time && time <= 20 ? formattedTime : '',
        })
      } else if (access) {
        acc.push({
          trad: `house.nears.${key}`,
          access: `house.nears.${access}`,
          time: time && time <= 20 ? formattedTime : '',
        })
      } else {
        acc.push({
          trad: `house.nears.${key}`,
          time: time && time <= 20 ? formattedTime : '',
        })
      }

      return acc
    },
    [],
  )
})

const tabs = computed(() => {
  const tabs = []

  if (environments.value?.length) {
    tabs.push(t('house.surrounding.subtitle1'))
  }

  if (nearBy.value?.length) {
    tabs.push(t('house.surrounding.subtitle2'))
  }

  return tabs
})

const coords = computed(() => ({
  lat: props.getGps.latitude,
  lng: props.getGps.longitude,
}))
</script>

<template>
  <div v-if="coords.lng && coords.lat" class="md:mb-2 md:pb-2">
    <h2 class="m-0 mb-1 text-2xl">
      {{ $t('house.surrounding.title') }}
    </h2>

    <HouseDestinationBreadcrumb
      class="mb-5"
      :house-destinations="houseDestinations"
    />

    <div class="mx-auto mb-6 lg:h-[70vh]">
      <BaseMap class="box-border h-56 lg:h-full" :coords="coords" />
    </div>

    <template v-if="isDesktopOrTablet">
      <div v-if="environments.length" class="mb-5">
        <h3 class="font-sansSerif text-base">
          {{ $t('house.surrounding.subtitle1') }}
        </h3>
        <BaseRow>
          <BaseCol
            v-for="(tag, i) in environments"
            :key="i"
            class="mb-2"
            cols="24"
            sm="12"
          >
            <span>{{ $t(tag.trad) }}</span>
          </BaseCol>
        </BaseRow>
      </div>

      <div v-if="nearBy.length">
        <h3 class="font-sansSerif text-base">
          {{ $t('house.surrounding.subtitle2') }}
        </h3>
        <div class="columns-2">
          <div
            v-for="(tag, i) in nearBy"
            :key="i"
            class="break-inside-avoid-column pb-2"
          >
            <div class="block">
              {{ $t(tag.trad) }}
              <br />
              <span v-if="tag.time" class="text-md text-gray-500">
                {{ tag.time }}
                <template v-if="tag.access">{{ $t(tag.access) }}</template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <BaseTabs v-else :tabs="tabs">
      <template #default="{ tabIndex }">
        <div v-if="tabIndex === 0">
          <BaseRow>
            <BaseCol
              v-for="(tag, i) in environments"
              :key="i"
              class="mb-2"
              cols="12"
            >
              <span>{{ $t(tag.trad) }}</span>
            </BaseCol>
          </BaseRow>
        </div>
        <div v-if="tabIndex === 1">
          <div class="columns-2">
            <div
              v-for="(tag, i) in nearBy"
              :key="i"
              class="break-inside-avoid-column pb-2"
            >
              <div class="block">
                {{ $t(tag.trad) }}
                <br />
                <span v-if="tag.time" class="text-md text-gray-500">
                  {{ tag.time }}
                  <template v-if="tag.access">{{ $t(tag.access) }}</template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseTabs>
  </div>
</template>

<style>
.leaflet-bar {
  box-shadow: none;
}
.leaflet-bar .leaflet-control-zoom-in,
.leaflet-bar .leaflet-control-zoom-out {
  border-radius: 0 !important;
}

.house-map .leaflet-interactive {
  @apply cursor-auto;
}
</style>
