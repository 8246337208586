import { periodPriceText, weeklyPriceRangeText } from '~/helpers/priceHelper'
import type { Locale, SupportedCurrencies } from 'lc-services/types'
import type { SelectedPeriod } from '~/types/inquiry/types'

type Step = 0 | 1 | 2
type HouseBooking = {
  currentStep: Step
  endDate: string | null
  price: {
    definitivePrice: boolean
    isValid: boolean
    text: string
    value: number | null
  }
  requestUuid: string | null
  startDate: string | null
  submitted: boolean
}

export const useHouseInquiryBooking = () => {
  const { locale, t } = useI18n<unknown, Locale>()
  const route = useRoute()
  const { $api } = useNuxtApp()
  const { userIsPartner } = useAuth()

  const houseBookingState = useState<HouseBooking>(
    'state-house-booking',
    () => ({
      currentStep: 0,
      endDate: null,
      price: {
        definitivePrice: false,
        isValid: false,
        text: '',
        value: null,
      },
      requestUuid: null,
      startDate: null,
      submitted: false,
    }),
  )

  const booking = computed(() => houseBookingState.value)

  const fetchAndsetSelectedPeriod = async ({
    currency,
    houseId,
    displayAvailabilities,
    displayPrices,
    minPrice,
    maxPrice,
  }: {
    currency: SupportedCurrencies
    houseId: number
    displayAvailabilities: boolean
    displayPrices: boolean
    minPrice: {
      CHF: number
      EUR: number
      GBP: number
      USD: number
    }
    maxPrice: {
      CHF: number
      EUR: number
      GBP: number
      USD: number
    }
  }) => {
    let selectedPeriod = {} as SelectedPeriod
    const defaultPrice = weeklyPriceRangeText(
      { t, locale },
      minPrice[currency],
      maxPrice[currency],
      currency,
      displayPrices,
    )

    if (booking.value.endDate) {
      const periodParams = {
        currency,
        end_at: booking.value.endDate,
        start_at: booking.value.startDate,
        private_token: route?.query['private-token'] || '',
      }

      try {
        const res = await $api.v1.housePeriods.read(houseId, {
          params: periodParams,
        })
        const { data } = res

        let validPrice = data.allMinimumDurationValid && data.fullyCovered
        if (!displayAvailabilities) {
          validPrice = true
        }

        selectedPeriod = { ...data, validPrice }

        let isValidPeriod =
          selectedPeriod?.fullyCovered &&
          selectedPeriod?.allMinimumDurationValid
        if (!displayAvailabilities) {
          isValidPeriod = true
        }

        if (isValidPeriod) {
          const price = periodPriceText(
            { t, locale },
            selectedPeriod,
            displayPrices,
            userIsPartner.value,
          )

          const definitivePrice = selectedPeriod.periods.every(
            (period) => period.definitivePrice,
          )

          setPrice({
            definitivePrice,
            isValid: selectedPeriod.validPrice,
            price: price.value,
            text: price.text,
          })
        } else {
          setPrice({
            definitivePrice: false,
            isValid: false,
            price: minPrice[currency],
            text: defaultPrice,
          })
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      setPrice({
        definitivePrice: false,
        isValid: false,
        price: null,
        text: '',
      })
    }

    return selectedPeriod
  }

  const setPrice = ({
    text,
    price,
    isValid,
    definitivePrice,
  }: {
    text: string
    price: number | null
    isValid: boolean
    definitivePrice: boolean
  }) => {
    let textFormatted = ''

    if (isValid) {
      const startDateFormat = locale.value === 'en' ? 'DD MMM' : 'DD/MM'
      const endDateFormat = locale.value === 'en' ? 'DD MMM YYYY' : 'DD/MM/YYYY'

      const formattedStart = $dayjs(
        booking.value.startDate,
        'YYYY-MM-DD',
      ).format(startDateFormat)
      const formattedEnd = $dayjs(booking.value.endDate, 'YYYY-MM-DD').format(
        endDateFormat,
      )

      textFormatted = t('house.price_date_range', {
        price: text,
        start: formattedStart,
        end: formattedEnd,
      })

      if (userIsPartner.value) {
        textFormatted = `${t('search.from')} ${textFormatted}`
      }
    } else {
      price = 0
    }

    houseBookingState.value.price = {
      text: textFormatted,
      value: price,
      isValid,
      definitivePrice,
    }
  }

  const setDates = (startDate: string | null, endDate: string | null) => {
    houseBookingState.value.endDate = endDate
    houseBookingState.value.startDate = startDate
  }

  const setStep = (step: Step) => {
    houseBookingState.value.currentStep = step
  }

  const setPrevStep = () => {
    if (houseBookingState.value.currentStep > 0) {
      houseBookingState.value.currentStep--
    }
  }

  const setNextStep = () => {
    houseBookingState.value.currentStep++
  }

  const setRequestUuid = (uuid: string) => {
    houseBookingState.value.requestUuid = uuid
  }

  const setSubmitted = (value: boolean) => {
    houseBookingState.value.submitted = value
  }

  return {
    booking,
    fetchAndsetSelectedPeriod,
    setDates,
    setNextStep,
    setPrevStep,
    setPrice,
    setRequestUuid,
    setStep,
    setSubmitted,
  }
}
