<script setup lang="ts">
import type { HouseDetails, HousePhotoAttributes } from 'lc-services/types'
import type { StateSearchHousePreviousPage } from '~/types/search/types'

const { $lcServicesSearch } = useNuxtApp()
const { trackEvent } = useTracking()
const stateHousePreviousPage = useState<StateSearchHousePreviousPage>(
  'house-previous-page',
)

const props = withDefaults(
  defineProps<{
    house: HouseDetails
    getPhotos: { src: string }[]
    matterportVideoUrl?: string
    totalPhotos: number
  }>(),
  {
    matterportVideoUrl: '',
  },
)
const emits = defineEmits<{
  'open-modal-virtual-visit': []
  'open-fullscreen': []
  'new-photos': [HousePhotoAttributes[]]
}>()

const localePath = useLocalePath()
const { searchController, searchPresenter } = $lcServicesSearch
const currentIndex = ref(0)
const hasLoadedPhotos = ref(false)
const nbPages = ref(2)
const page = ref(2)
const swiperOptions = {
  keyboard: true,
  loop: false,
}

const carouselImgStyle = computed(() => {
  return { img: { 'object-fit': 'cover' } }
})
const houseName = computed(() => props.house?.name || '')
const fullscreenImg = computed(() =>
  props.getPhotos[0]?.src ? props.getPhotos[0]?.src : '',
)

const openModalVirtualVisit = () => {
  emits('open-modal-virtual-visit')

  trackEvent({
    category: 'Product page',
    event: 'virtual_visit_requested',
    house_id: Number(props.house.id),
  })
}

const fetchPhotos = async () => {
  try {
    const params: Record<string, number | string> = { per: 2, page: page.value }

    if (props.house.privateToken) {
      params.private_token = props.house.privateToken
    }

    await searchController.getHousePhotos(props.house.id, {
      page: String(page.value),
      per: '2',
    })

    nbPages.value = searchPresenter.vm.housePhotos?.totalPages ?? 1

    if (nbPages.value > page.value) {
      page.value = page.value + 1
    }

    if (nbPages.value === page.value - 1) {
      hasLoadedPhotos.value = true
    }

    if (searchPresenter.vm.housePhotos)
      emits('new-photos', searchPresenter.vm.housePhotos.photos)
  } catch (err) {
    console.error(err)
  }
}

const paginate = async (index: number) => {
  currentIndex.value = index

  if (!hasLoadedPhotos.value) {
    await fetchPhotos()
  }
}
const openFullscreen = (open: boolean) => {
  if (open) {
    emits('open-fullscreen')
  }
}
</script>

<template>
  <div
    class="house-heading relative overflow-hidden"
    data-testid="open-fullscreen"
    @click="openFullscreen($device.isMobile ? false : true)"
  >
    <VirtualBaseCarousel
      v-if="!$device.isDesktop"
      ref="houseCarousel"
      class="h-full"
      class-name="house-carousel__slider h-full"
      :base-image-style-object="carouselImgStyle"
      :image-attr="{ alt: houseName }"
      :slides="getPhotos"
      :swiper-options="swiperOptions"
      @slide-change-active-index="paginate"
      @click-on-image="openFullscreen(true)"
    />

    <div
      v-else
      class="scale-1 h-full cursor-pointer transition duration-500 ease-in-out md:hover:scale-[1.015]"
    >
      <BaseNuxtImg :alt="houseName" :src="fullscreenImg" aspect-ratio="16/9" />
    </div>

    <div
      :class="[
        'lc-container absolute bottom-0 right-0 z-30 mx-auto w-auto pb-5',
        { 'right-0': !$device.isDesktop },
        { 'left-0': $device.isDesktop },
      ]"
    >
      <span v-if="!$device.isDesktop" class="house-photos-badge">
        {{ currentIndex + 1 }}/{{ totalPhotos }}
      </span>
      <div v-else class="flex">
        <base-button
          class="button-heading"
          color="white"
          has-icon
          @click.stop="openFullscreen(true)"
        >
          {{ $t('house.seePhotos', { number: totalPhotos }) }}
        </base-button>

        <base-button
          v-if="matterportVideoUrl"
          class="button-heading ml-4"
          color="white"
          has-icon
          @click.stop="openModalVirtualVisit"
        >
          {{ $t('house.virtualVisit.title') }}
        </base-button>
      </div>
    </div>

    <nuxt-link
      :aria-label="$t('global.back')"
      :to="localePath(stateHousePreviousPage)"
      class="absolute left-4 top-4 z-base flex size-8 items-center justify-center rounded-full bg-white text-gray-700 shadow lg:hidden"
      data-testid="back-button"
      type="button"
    >
      <BaseIcon class="mr-1" name="navArrowLeft" />
    </nuxt-link>
  </div>
</template>

<style>
.house-heading {
  height: 340px;
}
@screen md {
  .house-heading {
    height: 480px;
  }
}
@screen lg {
  .house-heading {
    height: 70vh;
  }
}
.no-scroll-wrap {
  @apply overflow-y-hidden;
}
.button-heading {
  @apply font-medium text-md bg-gray-700/80 px-4 py-3.5 rounded hover:bg-secondary-400;
}

.house-photos-badge {
  @apply font-medium text-md text-white bg-gray-700/50 px-2 py-1 rounded;
}

.house-carousel__slider .swiper {
  @apply h-full;
}
.house-carousel__slider .swiper img {
  @apply h-full w-full;
}
</style>
