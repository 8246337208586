<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = useBreakpoint()

const props = withDefaults(
  defineProps<{
    buttonCollapseText?: {
      open: string
      close: string
    }
    quote?: string
    trackingPage?: string
  }>(),
  {
    buttonCollapseText: () => ({
      open: 'house.readLess',
      close: 'house.readMore',
    }),
    quote: '',
    trackingPage: 'Product page',
  },
)

const descriptionIsOpen = ref(false)
const limitToTruncate = 170

const buttonText = computed(() => ({
  open: t(props.buttonCollapseText.open),
  close: t(
    props.buttonCollapseText.close.concat(isMobile.value ? 'Mobile' : ''),
  ),
}))

const isTruncate = computed(
  () => props.quote && props.quote.length > limitToTruncate,
)
const ellipsizeQuote = computed(() =>
  isTruncate.value
    ? props.quote.substring(0, limitToTruncate).concat('...')
    : props.quote,
)
</script>

<template>
  <div v-if="quote">
    <div v-if="isMobile" class="house-description__mobile">
      {{ descriptionIsOpen ? quote : ellipsizeQuote }}
      <span class="mr-3" />
      <BaseCollapse
        v-if="isTruncate"
        v-model="descriptionIsOpen"
        color="secondary"
        :button-text="buttonText"
        :icon="false"
      />
    </div>
    <div v-else class="mb-6 flex items-start">
      {{ descriptionIsOpen ? quote : ellipsizeQuote }}
    </div>

    <BaseCollapse
      v-if="isTruncate && !isMobile"
      v-model="descriptionIsOpen"
      color="black"
      :button-text="buttonText"
      :button-attrs="{ class: '!items-center' }"
    />
  </div>
</template>

<style scoped>
.house-description__mobile .lc-collapse {
  @apply inline-flex text-md font-bold;
}
</style>
